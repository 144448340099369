import { cx } from "class-variance-authority";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const Loader = ({
    loading,
    loadingClassName,
    loadingText: qLoadingText,
    className,
    children,
}: {
    loading: boolean;
    loadingClassName?: string;
    loadingText?: ReactNode;
    className?: string;
    children?: ReactNode;
}): JSX.Element => {
    const { t } = useTranslation("common");
    let child = <div className={className}>{children}</div>;
    let loadingText: ReactNode = <span>{`${t("loading", "Loading")}`}</span>;
    if (qLoadingText) loadingText = qLoadingText;
    if (loading)
        child = (
            <div className="flex min-h-max w-full flex-1 items-center justify-center">
                <div
                    className={cx(
                        loadingClassName,
                        "relative my-4 flex h-24 w-20",
                    )}
                >
                    <div className="absolute inset-0 z-[1] flex h-full items-center justify-center bg-white bg-opacity-30 transition-colors">
                        <div className="flex flex-col items-center gap-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 56 56"
                                className="h-14 w-14 animate-spin text-white"
                            >
                                <path
                                    fill="#EBEEFD"
                                    d="M56 28a28 28 0 1 1-56 0 28 28 0 0 1 56 0ZM7 28a21 21 0 1 0 42 0 21 21 0 0 0-42 0Z"
                                />
                                <path
                                    fill="#4361EE"
                                    d="M12 5a28 28 0 1 1 15.5 51l.1-7A21 21 0 1 0 16 10.8l-4-5.7Z"
                                />
                            </svg>
                            <span className="text-center text-neutral-700">
                                {loadingText}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    return <>{child}</>;
};
