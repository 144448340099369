import { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { cx } from "class-variance-authority";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<
    HTMLInputElement,
    InputProps & { prefixDiv?: ReactNode; suffixDiv?: ReactNode }
>(
    (
        { prefixDiv, suffixDiv, defaultValue, value, placeholder, ...props },
        ref,
    ): JSX.Element => {
        const style = {
            normal: cx(
                "border border-blue-grey-50 bg-white focus-within:border-2 focus-within:border-primary hover:border-2 hover:border-primary active:border-primary",
            ),
            disabled: cx(
                "border-blue-grey-50 bg-blue-grey-20 text-blue-grey-300 placeholder:text-blue-grey-300",
            ),
        };

        return (
            <div
                tabIndex={1}
                className={cx(
                    "typography-main flex h-10 items-center gap-3 rounded-lg border border-solid px-3 py-2.5",
                    props.disabled ? style.disabled : style.normal,
                    props.className,
                )}
            >
                {prefixDiv && <div>{prefixDiv}</div>}
                <input
                    ref={ref}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    {...props}
                    className="h-10 w-full rounded-md border-none bg-transparent p-0 text-left text-blue-grey-900 caret-primary-500 outline-none placeholder:text-blue-grey-400 disabled:text-blue-grey-400"
                />
                {suffixDiv && <div>{suffixDiv}</div>}
            </div>
        );
    },
);
Input.displayName = "Input";

export { Input };
